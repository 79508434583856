import axios from 'axios'
import store from '../store'

let getReservationsToken = null
let getLocationReservationsToken = null
let getExportgroupReservationsToken = null

export default {
  methods: {
    cancelGetLocationReservationsRequest() {
      if (getLocationReservationsToken) {
        getLocationReservationsToken.cancel()
      }
    },

    cancelGetExportgroupReservations() {
      if (getExportgroupReservationsToken) {
        getExportgroupReservationsToken.cancel()
      }
    },

    async getReservationById(reservationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation/${reservationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getReservationPdf(reservationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation/${reservationId}/pdf`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getReservations(
      searchTerm = '',
      channelId = 0,
      locationId = 0,
      profileId = 0,
      meetingtypeId = 0,
      month = 0,
      year = 0,
      statusId = 0,
      page = 0,
      itemsPerPage = 0
    ) {
      if (getReservationsToken) {
        getReservationsToken.cancel()
      }
      getReservationsToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/admin/reservations`,
        {
          cancelToken: getReservationsToken.token,

          params: {
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage,
            channelId: channelId,
            locationId: locationId,
            profileId: profileId,
            meetingtypeId: meetingtypeId,
            month: month,
            year: year,
            statusId: statusId
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getReservationsCsv(
      searchTerm = '',
      channelId = 0,
      locationId = 0,
      profileId = 0,
      meetingtypeId = 0,
      month = 0,
      year = 0,
      statusId = 0,
      page = 0,
      itemsPerPage = 0
    ) {
      if (getReservationsToken) {
        getReservationsToken.cancel()
      }
      getReservationsToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/admin/reservations/csv`,
        {
          cancelToken: getReservationsToken.token,

          params: {
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage,
            channelId: channelId,
            locationId: locationId,
            profileId: profileId,
            meetingtypeId: meetingtypeId,
            month: month,
            year: year,
            statusId: statusId
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationReservations(
      searchTerm = '',
      channelId = 0,
      locationId = 0,
      profileId = 0,
      companyId = 0,
      meetingtypeId = 0,
      statusId = 0,
      startDate = '1900-01-01',
      endDate = '1900-01-01',
      page = 0,
      itemsPerPage = 0
    ) {
      this.cancelGetLocationReservationsRequest()
      getLocationReservationsToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation/location/${locationId}`,
        {
          cancelToken: getLocationReservationsToken.token,

          params: {
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage,
            channelId: channelId,
            profileId: profileId,
            companyId: companyId,
            meetingtypeId: meetingtypeId,
            statusId: statusId,
            startDate: startDate,
            endDate: endDate,
            statusId: statusId
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getExportgroupReservations(
      exportgroupId,
      month = 0,
      year = 0,
      statusId = 2
    ) {
      this.cancelGetExportgroupReservations()
      getExportgroupReservationsToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation/exportgroup/${exportgroupId}`,
        {
          cancelToken: getExportgroupReservationsToken.token,

          params: {
            month: month,
            year: year,
            statusId: statusId
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getProfileReservations(
      profileId = 0,
      meetingtypeId = 0,
      page = 0,
      itemsPerPage = 0,
      showCancelled = false,
      showHistory = false,
      searchTerm = '',
      startDate = ''
    ) {
      if (startDate === '') {
        let day = 1 // newDateObj.getDate().toString()
        let month = 1 // (newDateObj.getMonth() + 1).toString()
        let year = 2012 // newDateObj.getFullYear().toString()

        if (month.length === 1) {
          month = '0' + month.toString()
        }

        if (day.length === 1) {
          day = '0' + day.toString()
        }

        startDate = year + '-' + month + '-' + day
      }

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation`,
        {
          params: {
            profileId: profileId,
            meetingtypeId: meetingtypeId,
            page: page,
            itemsPerPage: itemsPerPage,
            showCancelled: showCancelled,
            showHistory: showHistory,
            startDate: startDate,
            searchterm: searchTerm
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getReservationsWithoutInvoice(
      locationId,
      searchTerm = '',
      startDate,
      endDate,
      meetingtypeId = 0,
      showNoRevenue = false,
      showPaid = false
    ) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation/noinvoice`,
        {
          params: {
            locationId: locationId,
            meetingtypeId: meetingtypeId,
            q: searchTerm,
            startDate: startDate,
            endDate: endDate,
            showNoRevenue: showNoRevenue,
            showPaid: showPaid
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getReservationProgram(reservationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation/${reservationId}/program`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getReservationRevisions(reservationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation/${reservationId}/revisions`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveReservation(
      reservation,
      deleteSpaces,
      deleteOptions,
      deleteContacts,
      deleteTags
    ) {
      let criteria = {
        Reservation: reservation,
        DeleteSpaces: deleteSpaces,
        DeleteOptions: deleteOptions,
        DeleteContacts: deleteContacts,
        DeleteTags: deleteTags
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation/${reservation.Id}`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async addReservationSpace(reservationId, criteria) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation/${reservationId}/space`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteReservationContact(reservationId, contactId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation/${reservationId}/contact/${contactId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async copyReservation(criteria) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation/copy`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async acceptReservation(reservationId) {
      let criteria = {}

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation/${reservationId}/accept`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async rejectReservation(reservationId, reason) {
      let criteria = {
        Reason: reason
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation/${reservationId}/reject`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveReservationProgram(reservationId, programItems) {
      let criteria = {
        ProgramItems: programItems
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation/${reservationId}/program`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async cancelReservation(reservationId, reasonType = 0, reason = '') {
      let criteria = {
        ReasonType: reasonType,
        Reason: reason
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation/${reservationId}/cancel`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async lockReservationSpace(reservationId, reservationSpaceId) {
      let criteria = {
        ReservationId: reservationId,
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation/${reservationId}/space/${reservationSpaceId}/lock`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },

    async unlockReservationSpace(reservationId, reservationSpaceId) {
      let criteria = {
        ReservationId: reservationId,
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/reservation/${reservationId}/space/${reservationSpaceId}/unlock`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },

    //#region API V2 CALLS

    async saveReservationBasic(
      reservationId,
      name,
      profileId,
      companyId,
      statusId,
      language,
      changeAllInSet
    ) {
      let criteria = {
        Name: name,
        ProfileId: profileId,
        CompanyId: companyId,
        StatusId: statusId,
        Language: language,
        ChangeAllInSet: changeAllInSet,
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v2/reservation/${reservationId}`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },

    async saveReservationInvoiceAddress(
      reservationId,
      invoiceAddress,
      changeAllInSet
    ) {
      let criteria = invoiceAddress
      criteria.ChangeAllInSet = changeAllInSet

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v2/reservation/${reservationId}/address`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },

    async addReservationSpaces(reservationId, criteria) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v2/reservation/${reservationId}/spaces`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },

    async updateReservationSpace(reservationId, criteria) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v2/reservation/${reservationId}/space`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },

    async deleteReservationSpace(reservationId, criteria) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v2/reservation/${reservationId}/space`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
          data: criteria,
        }
      )
      return response
    },

    async addReservationOptions(reservationId, criteria) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v2/reservation/${reservationId}/options`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },

    async updateReservationOption(reservationId, criteria) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v2/reservation/${reservationId}/option`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },

    async deleteReservationOption(reservationId, criteria) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v2/reservation/${reservationId}/option`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
          data: criteria,
        }
      )
      return response
    },

    async addReservationContact(reservationId, criteria) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v2/reservation/${reservationId}/contact`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },

    async updateReservationContact(
      reservationId,
      contactId,
      contact,
      changeAllInSet
    ) {
      let criteria = {
        Id: contactId,
        Type: contact.Type,
        Name: contact.Name,
        Email: contact.Email,
        Phone: contact.Phone,
        ReceiveEmail: contact.ReceiveEmail,
        ChangeAllInSet: changeAllInSet,
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v2/reservation/${reservationId}/contact`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },

    async updateReservationOptionSortOrder(reservationId, reservationOptionIds) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v2/reservation/${reservationId}/option/sortorder`,
        reservationOptionIds,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken'],
          },
        }
      )
      return response
    },

    //#endregion
  }
}
